<template>
    <section>
        <div class="columns">
            <!--
            <div class="column col-6 col-sm-12 mb-4">
                <graphed-statistic-card
                    id="comparable_listings"
                    :title="translate('Comparable Listings')"
                    :count="count('comparable_listings')"
                    :graph-data="graph_data('comparable_listings')"
                    :zoomed="zoomed"
                    @toggle-zoom="toggle_zoom"
                >
                    <template v-slot:icon><span class="d-invisible"></span></template>
                    <template v-slot:subtitle>
                        {{ translate("in your general area and price range") }}
                    </template>
                    <template v-slot:delta><small class="text-gray">&nbsp;</small></template>
                </graphed-statistic-card>
            </div>
            <div class="column col-6 col-sm-12 mb-4">
                <graphed-statistic-card
                    id="comparable_sales"
                    :title="translate('Comparable Sales')"
                    :count="count('comparable_sales')"
                    :graph-data="graph_data('comparable_sales')"
                    :zoomed="zoomed"
                    @toggle-zoom="toggle_zoom"
                >
                    <template v-slot:icon><span class="d-invisible"></span></template>
                    <template v-slot:subtitle>
                        {{ translate("in your general area and price range") }}
                    </template>
                    <template v-slot:delta><small class="text-gray">&nbsp;</small></template>
                </graphed-statistic-card>
            </div>
            //-->
            <div class="column col-6">
                <graphed-statistic-card
                    id="listings"
                    :title="translate('Active Listings')"
                    :count="count('listings')"
                    :graph-data="graph_data('listings')"
                    :zoomed="zoomed"
                    @toggle-zoom="toggle_zoom"
                >
                    <template v-slot:icon><span class="d-invisible"></span></template>
                    <template v-slot:subtitle>
                        {{ translate("in any area or price range") }}
                    </template>
                    <template v-slot:delta><small class="text-gray">&nbsp;</small></template>
                </graphed-statistic-card>
            </div>
            <div class="column col-6">
                <graphed-statistic-card
                    id="sales"
                    :title="translate('Sales')"
                    :count="count('sales')"
                    :graph-data="graph_data('sales')"
                    :zoomed="zoomed"
                    @toggle-zoom="toggle_zoom"
                >
                    <template v-slot:icon><span class="d-invisible"></span></template>
                    <template v-slot:subtitle>
                        {{ translate("in any area or price range") }}
                    </template>
                    <template v-slot:delta><small class="text-gray">&nbsp;</small></template>
                </graphed-statistic-card>
            </div>
        </div>
        <default-button
            flavor="link"
            :block="true"
            class="mt-4"
            @click.prevent.stop="viewing_chance_of_selling = !viewing_chance_of_selling"
        >
            <open-icon glyph="tachometer-alt" /> {{ translate("Selling Gauge") }}
        </default-button>

        <div>
            <listing-comparables
                :listing="listing"
            />
            <div
                v-if="!!setting('comparables_fineprint')"
                v-html="setting('comparables_fineprint')"
                class="text-small mt-4"
            ></div>
        </div>

        <modal-dialog
            id="chance-of-selling-dialog"
            :show.sync="viewing_chance_of_selling"
            class="modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Chances Of Selling") }}</span>
            </template>
            <div>
                {{ translate('Based on our system algorithms we take into account competitive listings number of sales inventory ratios and number of showings, time and other factors are analyzed to determine the chance of selling this is intended as a general guage and many other economic factors effect selling. Your Realtor will advise on best strategy based on your selling goals') }}
                <chance-of-selling-graphs
                    v-if="!!listing"
                    :listing="listing || {}"
                    class="mt-8"
                />
            </div>
        </modal-dialog>
    </section>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"
import { uses_destination_modal } from "@/custom/mixins"
import is_seller_dashboard_section from "./IsSellerDashboardSection"

import { ModalDialog, DefaultButton, OpenIcon } from "@/nibnut/components"
import { GraphedStatisticCard, ChanceOfSellingGraphs, ListingComparables } from "@/custom/components"

export default {
    name: "SellerStatsAndComparables",
    mixins: [is_remote_data_table_source, uses_destination_modal, is_seller_dashboard_section],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon,
        GraphedStatisticCard,
        ChanceOfSellingGraphs,
        ListingComparables
    },
    watch: {
        "listing.id": "reset"
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.listing.id)
        },
        post_load (response) {
            this.statistics = response.meta.statistics || {}
            this.center = { lat: this.listing.latitude, lng: this.listing.longitude }
        },
        count (graph_id) {
            const { showings, ...date_statistics } = this.statistics
            const statistics = Object.values(date_statistics)
            return statistics.length ? statistics[statistics.length - 1][graph_id] : 0
        },
        graph_data (graph_id) {
            const graph_data = {}
            const keys = Object.keys(this.statistics)
            keys.forEach(key => {
                graph_data[key] = this.statistics[key][graph_id]
            })
            return graph_data
        },
        toggle_zoom (graph_id) {
            if(this.zoomed === graph_id) this.zoomed = null
            else this.zoomed = graph_id
        },
        maybe_set_center (event) {
            if(!!event && !!event.center) this.center = event.center
        }
    },
    computed: {
        state_identifier () {
            return "seller-listing-comparables-state"
        },
        can_refresh () {
            return this.profile_id && this.listing && this.listing.id
        },
        fields () {
            return ["fieldset::seller-dashboard"]
        },
        map_config () {
            const center = this.center || { lat: 48.9705506, lng: -123.9371303 }
            const zoom = this.center ? 12 : 10
            return {
                center,
                zoom
            }
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 1,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "stats-only",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                statistics: "dashboard-comparables"
            },
            apply_local_filter: false,
            zoomed: null,
            statistics: {},
            center: null,
            viewing_chance_of_selling: false
        }
    }
}
</script>
