<template>
    <listing-view
        v-if="!!current_listing"
        :listing="current_listing"
        :sections="sections"
    >
        <template v-slot:hero>
            <seller-hero
                ref="hero"
                :listing="current_listing"
                :title="current_listing.computed_display_title || ''"
                :active="true"
            />
            <div
                v-if="!!data_version && (filtered_rows.length > 1)"
                class="carousel"
                :style="{ 'background-color': (invisible ? 'transparent': 'initial') }"
            >
                <input
                    v-for="(listing, index) in filtered_rows"
                    :key="listing.id"
                    :id="listing.id"
                    type="radio"
                    :name="`carousel-radio-${index}`"
                    hidden=""
                    :checked="listing.id === current_listing.id"
                    class="carousel-locator"
                />
                <div class="carousel-container">
                    <seller-hero
                        v-for="(listing, index) in filtered_rows"
                        :key="listing.id"
                        :listing="listing"
                        :title="listing.computed_display_title || ''"
                        :active="listing.id === current_listing.id"
                        :class="{ active: listing.id === current_listing.id, 'd-invisible': invisible }"
                        class="carousel-item"
                    >
                        <label
                            v-if="index > 0"
                            :for="filtered_rows[index - 1].id"
                            class="item-prev btn btn-action btn-lg"
                            @click.prevent.stop="current_listing = filtered_rows[index - 1]"
                        >
                            <open-icon glyph="chevron-circle-left" size="2x" />
                        </label>
                        <label
                            v-if="index < (filtered_rows.length - 1)"
                            :for="filtered_rows[index + 1].id"
                            class="item-next btn btn-action btn-lg"
                            @click.prevent.stop="current_listing = filtered_rows[index + 1]"
                        >
                            <open-icon glyph="chevron-circle-right" size="2x" />
                        </label>
                    </seller-hero>
                </div>
                <div class="carousel-nav">
                    <label
                        v-for="(listing, index) in filtered_rows"
                        :key="listing.id"
                        :for="listing.id"
                        :class="{ active: (listing.id === current_listing.id) }"
                        class="nav-item text-hide c-hand"
                        @click.prevent.stop="current_listing = listing"
                    >
                        {{ index }}
                    </label>
                </div>
            </div>
        </template>
    </listing-view>
</template>

<script>
import { mapGetters } from "vuex"
import { is_remote_data_table_source } from "@/nibnut/mixins"

import { OpenIcon } from "@/nibnut/components"
import { ListingView } from "@/custom/components"
import SellerHero from "./SellerHero"
import SellerChanceOfSelling from "./SellerChanceOfSelling"
import SellerNewsAndShowings from "./SellerNewsAndShowings"
import SellerFeedback from "./SellerFeedback"
import SellerStatsAndComparables from "./SellerStatsAndComparables"
import SellerResources from "./SellerResources"

// Component loads slides ; each slide, when activated, loads the complete listing data and relationships
export default {
    name: "SellerDashboard",
    mixins: [is_remote_data_table_source],
    components: {
        OpenIcon,
        ListingView,
        SellerHero
    },
    methods: {
        pre_load () {
            this.set_state_values({
                seller_id: this.profile.id
            })
        },
        post_load () {
            if(this.current_listing && this.current_listing.id && !this.entity_record("listing", this.current_listing.id)) this.current_listing = {}
            if(this.$route.params.uuid) this.current_listing = this.entity_record("listing", this.$route.params.uuid)
            if(!this.current_listing || (!this.current_listing.id && !!this.filtered_rows.length)) this.current_listing = this.filtered_rows[0]
            if(this.invisible) {
                setTimeout(() => {
                    this.invisible = false
                }, 1000)
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "seller-dashboard-global-state"
        },
        fields () {
            return [
                "fieldset::seller-dashboard",
                "ns::listing_website;fieldset::seller-dashboard"
            ]
        },
        sections () {
            return [
                { id: "statistics", title: this.translate("Chance of Selling"), component: SellerChanceOfSelling },
                { id: "news-showings", title: this.translate("News & Showings"), component: SellerNewsAndShowings },
                { id: "feedback", title: this.translate("Feedback"), component: SellerFeedback },
                { id: "stats-comparables", title: this.translate("Comparables"), component: SellerStatsAndComparables },
                { id: "resources", title: this.translate("Resources"), component: SellerResources }
            ]
        }
    },
    data () {
        return {
            entity: "listing",
            relation_ids: ["listing_website"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "on_market_on",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                seller_id: 0,
                listing_uuid: null
            },
            current_listing: {},
            invisible: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.dashboard #dashboard-seller {
    position: relative;

    .page-content-header {
        display: none;
    }
    #listing-view-page {
        & > div:not(#page-toc) {
            position: relative;
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
