<template>
    <section v-if="!!listing && !!listing.links && !!listing.links.length">
        <div
            class="card"
        >
            <div class="card-header">
                <div class="card-title h5">
                    {{ translate("Links to resources") }}
                </div>
            </div>
            <div class="card-body">
                <base-link
                    v-for="(link, index) in listing.links"
                    :key="index"
                    :href="link"
                    target="_blank"
                    class="chip"
                >
                    {{ link }}
                    <open-icon glyph="external-link-alt" class="ml-2" />
                </base-link>
            </div>
        </div>
    </section>
</template>

<script>
import is_seller_dashboard_section from "./IsSellerDashboardSection"

import { BaseLink, OpenIcon } from "@/nibnut/components"

export default {
    name: "SellerResources",
    mixins: [is_seller_dashboard_section],
    components: {
        BaseLink,
        OpenIcon
    },
    watch: {
    },
    methods: {
    },
    computed: {
    },
    props: {
        listing: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
        }
    }
}
</script>
